import textResource from '../../../../utils/text-resources/omalaina/FI/messages';
import submit from '../../../../mappers/FI/application';
import email from '../../../../validators/FI/email';
import validatePhoneNumberFi from '../../../../validators/FI/phoneNumber';
import { onlyNumbers } from '../../../../validators';
import LOAN_LIMITS from '../../../../localizations/config/FI/shared/loan-limits';
import { validateRequiredCheckbox } from '../../../../validators/requiredButtonGroup';

const data = {
  general: {
    mapper: submit,
  },
  backgroundColor: 'var(--white-opacity)',
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.applicationLoanAmountLabel,
            value: 'totalAmount',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              name: 'totalAmount',
              type: 'tel',
              maxValue: LOAN_LIMITS.UP_TO,
              suffix: textResource.loanCalculatorCurrencySuffix,
              placeholder: textResource.loanCalculatorTotalLoanAmountPlaceholder,
              validate: onlyNumbers,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 1,
              end: 3,
              mobileOrder: '-1',
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.repaymentTimeLabel,
            value: 'repaymentTime',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              suffix: textResource.loanCalculatorRepaymentTimeSuffix,
              name: 'repaymentTime',
              type: 'tel',
              placeholder: textResource.repaymentTimePlaceholder,
              maxValue: LOAN_LIMITS.YEARS_TO,
              validate: onlyNumbers,
              validateOnRegister: true,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 3,
              end: 7,
              mobileOrder: '0',
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'totalAmount',
            showButtons: true,
            type: 'tel',
            lead: true,
            sliderConfig: {
              range: {
                min: LOAN_LIMITS.FROM,
                max: LOAN_LIMITS.UP_TO,
              },
              step: LOAN_LIMITS.STEP,
            },
            layout: {
              start: 1,
              end: 3,
              mobileOrder: '-1',
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'repaymentTime',
            showButtons: true,
            type: 'tel',
            lead: true,
            sliderConfig: {
              range: {
                min: LOAN_LIMITS.YEARS_FROM,
                max: LOAN_LIMITS.YEARS_TO,
              },
            },
            layout: {
              start: 3,
              end: 7,
              mobileOrder: '0',
            },
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            costLabel: textResource.monthlyCostApproximateCostLabel,
            approximateMonthlyCostDesktop: textResource.monthlyCostApproximateCostPerMonthDesktop,
            approximateMonthlyCostMobile: textResource.monthlyCostApproximateCostPerMonthMobile,
            approximateMonthlyCostYears: textResource.monthlyCostApproximateCostLabelYears,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.repaymentTimeWarning,
            transparent: true,
            visibleWhen: 'return(formState.values.repaymentTime > 15)',
            margin: false,
            center: false,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            label: textResource.phoneNumberLabel,
            validate: validatePhoneNumberFi,
            hasValidateFunction: true,
            showErrorMessage: false,
            type: 'tel',
            placeholder: textResource.phoneNumberPlaceholder,
            lead: true,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            validate: email,
            hasValidateFunction: true,
            showErrorMessage: false,
            label: textResource.emailLabel,
            placeholder: textResource.emailPlaceholder,
            lead: true,
            layout: {
              start: 3,
              end: 6,
            },
          },
        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.acceptNewsletterConsentText,
            lead: true,
            layout: {
              start: 1,
              end: 6,
            },
          },
        },
        {
          component: 'AcceptAgreement',
          form: 'Checkbox',
          props: {
            name: 'acceptAgreement',
            text: textResource.acceptAgreement,
            linkText: textResource.termsAndIntegrityPolicyLinksIntegrityPolicy,
            linkTextPolicy: textResource.termsAndIntegrityPolicyLinksTerms,
            linkDataPolicy: {
              FI: 'https://www.omalaina.fi/rekisteriseloste/',
              EN: 'https://www.omalaina.fi/en/privacy-policy/',
            },
            linkUserAgreement: {
              FI: 'https://www.omalaina.fi/rekisteriseloste/',
              EN: 'https://www.omalaina.fi/en/user-agreement/',
            },
            hasValidateFunction: true,
            validate: validateRequiredCheckbox,
            linkInText: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationPath: '/hae-lainaa/',
            engDestinationPath: '/en/personal-loan/',
            sendAbandonCartEvent: true,
            label: textResource.applyLabel,
            ctaBtnStyle: true,
            disableButton: false,
            lead: true,
            layout: {
              start: 3,
              end: 6,
            },
          },
        },
      ],
    },
  ],
};

export default data;

import LOAN_PURPOSES from '../../../../localizations/config/FI/shared/loan-purposes';
import EMPLOYMENT_TYPES from '../../../../localizations/config/FI/shared/employment-types';
import HOME_TYPES from '../../../../localizations/config/FI/shared/home-types';
import MARITAL_STATUS from '../../../../localizations/config/FI/shared/marital-status';
import DEPENDANTS from '../../../../localizations/config/FI/shared/dependants';
import EDUCATION_TYPES from '../../../../localizations/config/FI/shared/education-types';
import MILITARY_TYPES from '../../../../localizations/config/FI/shared/military-types';
import OCCUPATION_CATEGORY from '../../../../localizations/config/FI/shared/occupation-category';
import CITIZENSHIPS from '../../../../localizations/config/FI/shared/citizenships';
import HOME_TYPE_CATEGORIES from '../../../../localizations/config/FI/shared/home-type-categories';
import DATAYEARSELECT from '../../../../localizations/config/FI/omalaina/dateyear-select';
import LOAN_TYPES from '../../../../localizations/config/FI/omalaina/loan-types';

import {
  homeCost,
  onlyNumbers,
  numbersZeroAndEmpty,
  employerName,
  otherCost,
} from '../../../../validators';

import { validateMonthlySalaryBeforeTax, spouseMonthlyIncomeBeforeTaxValidator } from '../../../../validators/FI/monthlySalaryBeforeTax';
import monthlySalaryAfterTax from '../../../../validators/FI/monthlySalaryAfterTax';
import otherIncomeBeforeTax from '../../../../validators/otherIncomeBeforeTax';
import { validateEmploymentSinceYear, validateEmploymentSinceMonth } from '../../../../validators/employedSince';
import validatePhoneNumberFi from '../../../../validators/FI/phoneNumber';
import email from '../../../../validators/FI/email';
import { validateEmploymentToYear } from '../../../../validators/employedTo';
import { validateRequiredButtonGroup, validateRequiredCheckbox } from '../../../../validators/requiredButtonGroup';
import validateMoveInDate from '../../../../validators/FI/validateMoveInDate';
import validateRequiredTextInput from '../../../../validators/requiredTextInput';
import validateBankAccount from '../../../../validators/FI/bankAccount';
import validateNationalId from '../../../../validators/FI/nationalId';
import LOAN_LIMITS from '../../../../localizations/config/FI/shared/loan-limits';
import validateTotalLoan from '../../../../validators/totalLoan';
import validateRepaymentTime from '../../../../validators/repaymentTime';

import textResource from '../../../../utils/text-resources/omalaina/FI/messages';
import submit from '../../../../mappers/FI/application';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {
    mapper: submit,
  },
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
    userAgreementEng: 'https://www.omalaina.fi/en/user-agreement/',
    userAgreement: 'https://www.omalaina.fi/kayttajaehdot/',
    dataPolicy: 'https://www.omalaina.fi/rekisteriseloste/',
    dataPolicyEng: 'https://www.omalaina.fi/en/data-protection-policy/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.applicationLoanAmountLabel,
            value: 'totalAmount',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              name: 'totalAmount',
              type: 'tel',
              tooltip: tooltip(textResource.applicationLoanAmountTooltip),
              maxValue: LOAN_LIMITS.UP_TO,
              suffix: textResource.loanCalculatorCurrencySuffix,
              placeholder: textResource.loanCalculatorTotalLoanAmountPlaceholder,
              validate: onlyNumbers,
              validateOnRegister: true,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 1,
              end: 3,
              mobileOrder: '-1',
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.repaymentTimeLabel,
            value: 'repaymentTime',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              suffix: textResource.loanCalculatorRepaymentTimeSuffix,
              name: 'repaymentTime',
              type: 'tel',
              placeholder: textResource.repaymentTimePlaceholder,
              tooltip: tooltip(textResource.repaymentTimeTooltip),
              maxValue: LOAN_LIMITS.YEARS_TO,
              validateOnRegister: true,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 3,
              end: 7,
              mobileOrder: '0',
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'totalAmount',
            showButtons: true,
            type: 'tel',
            lead: true,
            validate: validateTotalLoan,
            sliderConfig: {
              range: {
                min: LOAN_LIMITS.FROM,
                max: LOAN_LIMITS.UP_TO,
              },
              step: LOAN_LIMITS.STEP,
            },
            layout: {
              start: 1,
              end: 3,
              mobileOrder: '-1',
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'repaymentTime',
            showButtons: true,
            type: 'tel',
            lead: true,
            validate: validateRepaymentTime,
            sliderConfig: {
              range: {
                min: 1,
                max: 20,
              },
            },
            layout: {
              start: 3,
              end: 7,
              mobileOrder: '0',
            },
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            costLabel: textResource.monthlyCostApproximateCostLabel,
            approximateMonthlyCostDesktop: textResource.monthlyCostApproximateCostPerMonthDesktop,
            approximateMonthlyCostMobile: textResource.monthlyCostApproximateCostPerMonthMobile,
            approximateMonthlyCostYears: textResource.monthlyCostApproximateCostLabelYears,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.repaymentTimeWarning,
            transparent: true,
            visibleWhen: 'return(formState.values.repaymentTime > 15)',
            margin: false,
            center: false,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.personalDataHeadline,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            label: textResource.emailLabel,
            placeholder: textResource.emailPlaceholder,
            tooltip: tooltip(textResource.emailTooltip),
            validate: email,
            hasValidateFunction: true,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            label: textResource.phoneNumberLabel,
            type: 'tel',
            placeholder: textResource.phoneNumberPlaceholder,
            tooltip: tooltip(textResource.phoneNumberTooltip),
            hasValidateFunction: true,
            validate: validatePhoneNumberFi,
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'nationalId',
            label: textResource.nationalIdLabel,
            placeholder: textResource.nationalIdPlaceholder,
            tooltip: tooltip(textResource.nationalIdTooltip),
            hasValidateFunction: true,
            validate: validateNationalId,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'citizenship',
            label: textResource.citizenshipLabel,
            labelOutside: true,
            placeholder: textResource.citizenshipPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: CITIZENSHIPS,
            layout: {
              start: 4,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'civilStatus',
            label: textResource.civilStatusLabel,
            labelOutside: true,
            placeholder: textResource.civilStatusPlaceholder,
            tooltip: tooltip(textResource.civilStatusTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: MARITAL_STATUS,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },

        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'dependants',
            label: textResource.dependantsLabel,
            labelOutside: true,
            placeholder: textResource.dependantsPlaceholder,
            tooltip: tooltip(textResource.dependantsTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: DEPENDANTS,
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'militaryServiceStatus',
            label: textResource.militaryTypeLabel,
            labelOutside: true,
            placeholder: textResource.militaryTypePlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: MILITARY_TYPES,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          sectionName: 'applicant',
          form: 'Select',
          props: {
            name: 'politicallyExposed',
            label: textResource.isPepLabel,
            placeholder: textResource.chooseOnePlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            tooltip: tooltip(textResource.isPepTooltip),
            options: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            layout: {
              start: 1,
              end: 4,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.housingContactDataHeadline,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'homeType',
            label: textResource.homeTypeLabel,
            labelOutside: true,
            placeholder: textResource.homeTypePlaceholder,
            tooltip: tooltip(textResource.homeTypeTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: HOME_TYPES,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'homeTypeCategory',
            label: textResource.homeTypeCategoryDescriptionLabel,
            labelOutside: true,
            placeholder: textResource.homeTypeCategoryDescriptionPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: HOME_TYPE_CATEGORIES,
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'applicant',
          props: {
            name: 'moveInDate',
            label: textResource.moveInDateLabel,
            placeholder: textResource.moveInDatePlaceholder,
            validate: validateMoveInDate,
            hasValidateFunction: true,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            type: 'tel',
            label: textResource.homeCostLabel,
            placeholder: textResource.homeCostPlaceholder,
            tooltip: tooltip(textResource.homeCostTooltip),
            hasValidateFunction: true,
            validate: homeCost,
            layout: {
              start: 4,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'otherLivingCost',
            label: textResource.otherLivingCostLabel,
            placeholder: textResource.otherLivingCostPlaceholder,
            tooltip: tooltip(textResource.otherLivingCostTooltip),
            hasValidateFunction: true,
            validate: otherCost,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.educationWorkDataHeadline,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'educationLevel',
            label: textResource.educationLabel,
            labelOutside: true,
            placeholder: textResource.educationPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: EDUCATION_TYPES,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'employmentType',
            label: textResource.employmentTypeLabel,
            labelOutside: true,
            placeholder: textResource.employmentTypePlaceholder,
            tooltip: tooltip(textResource.employmentTypeTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: EMPLOYMENT_TYPES,
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'applicant',
          props: {
            name: 'employmentSince',
            validate: validateEmploymentSinceYear,
            label: textResource.employmentSinceYearLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employmentSinceYearLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedSinceYearDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'applicant',
                    label: textResource.studentSinceDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'applicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'applicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                  {
                    name: 'unemployed',
                    sectionName: 'applicant',
                    label: textResource.unemployedSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.employmentSinceYearPlaceholder,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.employmentSinceYearTooltip),
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'applicant',
          props: {
            name: 'employmentSinceMonth',
            validate: validateEmploymentSinceMonth,
            hasValidateFunction: true,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employmentSinceMonthLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedSinceMonthDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'applicant',
                    label: textResource.studentSinceMonthDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'applicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'applicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                ],
              },
            ],
            label: textResource.employmentSinceMonthLabel,
            placeholder: textResource.employmentSinceMonthPlaceholder,
            visibleWhen: 'return (formState.values.applicant.employmentSince > new Date().getFullYear() - 4);',
            tooltip: tooltip(textResource.employmentSinceMonthTooltip),
            layout: {
              start: 4,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          sectionName: 'applicant',
          form: 'TextInput',
          props: {
            name: 'employerName',
            label: textResource.employerNameLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employerNameLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedEmployerNameDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.employerNamePlaceholder,
            tooltip: tooltip(textResource.employerNameTooltip),
            hasValidateFunction: true,
            validate: employerName,
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed") );',
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'occupationTitle',
            label: textResource.occupationTitleLabel,
            placeholder: textResource.occupationTitlePlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredTextInput,
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed") );',
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'occupationCategory',
            label: textResource.occupationCategoryLabel,
            labelOutside: true,
            placeholder: textResource.occupationCategoryPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed") );',
            options: OCCUPATION_CATEGORY,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'employmentTo',
            type: 'tel',
            label: textResource.employedToYearLabel,
            placeholder: textResource.employedToYearPlaceholder,
            tooltip: tooltip(textResource.employedToTooltip),
            validate: validateEmploymentToYear,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.applicant.employmentType === "temporary" || formState.values.applicant.employmentType === "parentalLeave");',
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'applicant',
          props: {
            name: 'employmentToMonth',
            label: textResource.employedToMonthLabel,
            labelOutside: true,
            placeholder: textResource.employedToMonthPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.applicant.employmentType === "temporary" || formState.values.coApplicant.employmentType === "parentalLeave");',
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'monthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.monthlyIncomeBeforeTaxLabel,
            placeholder: textResource.monthlyIncomeBeforeTaxPlaceholder,
            hasValidateFunction: true,
            validate: validateMonthlySalaryBeforeTax,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'monthlyIncomeAfterTax',
            isNumber: true,
            type: 'tel',
            label: textResource.monthlyIncomeAfterTaxLabel,
            placeholder: textResource.monthlyIncomeAfterTaxPlaceholder,
            hasValidateFunction: true,
            validate: monthlySalaryAfterTax,
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'spouseMonthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.spouseMonthlyIncomeLabel,
            placeholder: textResource.spouseMonthlyIncomePlaceholder,
            hasValidateFunction: true,
            validate: spouseMonthlyIncomeBeforeTaxValidator,
            visibleWhen: 'return (formState.values.applicant.civilStatus && (formState.values.applicant.civilStatus === "married" || formState.values.applicant.civilStatus === "partner"));',
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Checkbox',
          sectionName: 'applicant',
          props: {
            name: 'otherIncome',
            label: textResource.otherIncomeLabel,
            labelLayout: 'center; margin-right: 0;',
            tooltip: tooltip(textResource.coApplicantOtherIncomeTooltip),
            hasValidateFunction: true,
            buttonLayout: 'toggle',
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'otherMonthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.grossOtherIncomeLabel,
            placeholder: textResource.grossOtherIncomePlaceholder,
            hasValidateFunction: true,
            validate: otherIncomeBeforeTax,
            visibleWhen: 'return (formState.values.applicant.otherIncome !== undefined && formState.values.applicant.otherIncome === true);',
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.debtBreakdownLabel,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.currentLoansDesc,
            transparent: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ExpandingGridList',
          props: {
            name: 'currentLoanList',
            sectionName: 'applicant',
            addButtonLabel: textResource.loanAdd,
            removeButtonLabel: textResource.loanRemoval,
            columns: [
              {
                component: 'Select',
                name: 'type',
                placeholder: textResource.creditCardPlaceholder,
                hasValidateFunction: true,
                label: textResource.loanTypeLabel,
                validate: validateRequiredButtonGroup,
                options: LOAN_TYPES,
              },
              {
                component: 'TextInput',
                name: 'amount',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanAmountLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.loanAmountTooltip),
              },
              {
                component: 'TextInput',
                name: 'monthlyPayment',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanMonthlyCostLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.loanMonthlyCostTooltip),
              },
              {
                component: 'Checkbox',
                name: 'transfer',
                label: textResource.loanTransferLabel,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['consumer', 'creditCard'],
                  initialValue: false,
                },
              },
              {
                component: 'Select',
                name: 'loanShare',
                label: textResource.houseLoansPartLabel,
                hasValidateFunction: true,
                placeholder: textResource.civilStatusPlaceholder,
                validate: validateRequiredButtonGroup,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['mortgage', 'car'],
                },
                options: [
                  {
                    label: textResource.houseLoansLoanIsOwnedByApplicant,
                    value: '100',
                  },
                  {
                    label: textResource.houseLoansLoanIsSharedWithCoApplicant,
                    value: '50',
                  },
                ],
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'TestCurrentLoanSummary',
          props: {
            visibleWhen: 'return (!formState.values.hasCoApplicant);',
            tooltip: tooltip(textResource.currentLoansSumTooltip),
            title: textResource.currentLoansSumTitle,
            newLoanLabel: textResource.currentLoansSumNew,
            refinanceLoanLabel: textResource.currentLoansSumRefinance,
            totalLabel: textResource.currentLoansSumTotal,
            suffix: textResource.loanCalculatorCurrencySuffix,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'TotalLoanWarning',
          props: {
            visibleWhen: 'return (!formState.values.hasCoApplicant);',
            text: textResource.currentLoansTotalWarning,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            visibleWhen: 'return (!formState.values.hasCoApplicant);',
            label: textResource.applicationLoanAmountLabel,
            value: 'totalAmount',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              name: 'totalAmount',
              type: 'tel',
              tooltip: tooltip(textResource.applicationLoanAmountTooltip),
              maxValue: LOAN_LIMITS.UP_TO,
              suffix: textResource.loanCalculatorCurrencySuffix,
              placeholder: textResource.loanCalculatorTotalLoanAmountPlaceholder,
              validate: onlyNumbers,
              validateOnRegister: true,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            visibleWhen: 'return (!formState.values.hasCoApplicant);',
            name: 'totalAmount',
            showButtons: true,
            type: 'tel',
            lead: true,
            validate: validateTotalLoan,
            sliderConfig: {
              range: {
                min: LOAN_LIMITS.FROM,
                max: LOAN_LIMITS.UP_TO,
              },
              step: LOAN_LIMITS.STEP,
            },
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'hasCreditCard',
            label: textResource.creditCardLabel,
            labelOutside: true,
            placeholder: textResource.creditCardPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.bankDataHeadline,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          props: {
            name: 'bankAccount',
            type: 'text',
            convertToUpperCase: true,
            label: textResource.bankAccountLabel,
            placeholder: textResource.bankAccountPlaceholder,
            tooltip: tooltip(textResource.bankAccountTooltip),
            hasValidateFunction: true,
            validate: validateBankAccount,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.coApplicantDataHeadline,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.addCoApplicantLabel,
            tooltip: tooltip(textResource.addCoApplicantTooltip),
            transparent: true,
            header: true,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Checkbox',
          props: {
            name: 'hasCoApplicant',
            rightPadding: true,
            label: textResource.addCoApplicantDesc,
            tooltip: tooltip(textResource.addCoApplicantTooltip),
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'undefined',
              },
            ],
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.coAppPersonalDataHeadline,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            coApp: true,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'coApplicant',
          props: {
            name: 'email',
            label: textResource.coApplicantEmailLabel,
            placeholder: textResource.coApplicantEmailPlaceholder,
            tooltip: tooltip(textResource.coApplicantEmailTooltip),
            hasValidateFunction: true,
            validate: email,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'phoneNumber',
            type: 'tel',
            label: textResource.coApplicantPhoneNumberLabel,
            placeholder: textResource.coApplicantPhoneNumberPlaceholder,
            tooltip: tooltip(textResource.coApplicantPhoneNumberTooltip),
            hasValidateFunction: true,
            validate: validatePhoneNumberFi,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'nationalId',
            label: textResource.coApplicantNationalIdLabel,
            placeholder: textResource.coApplicantNationalIdPlaceholder,
            tooltip: tooltip(textResource.coApplicantNationalIdTooltip),
            hasValidateFunction: true,
            validate: validateNationalId,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'citizenship',
            label: textResource.coApplicantCitizenshipLabel,
            labelOutside: true,
            placeholder: textResource.coApplicantCitizenshipPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: CITIZENSHIPS,
            layout: {
              start: 4,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'civilStatus',
            label: textResource.coApplicantCivilStatusLabel,
            labelOutside: true,
            placeholder: textResource.coApplicantCivilStatusPlaceholder,
            tooltip: tooltip(textResource.coApplicantCivilStatusTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: MARITAL_STATUS,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'dependants',
            label: textResource.coApplicantDependantsLabel,
            labelOutside: true,
            placeholder: textResource.coApplicantDependantsPlaceholder,
            tooltip: tooltip(textResource.coApplicantDependantsTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: DEPENDANTS,
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'militaryServiceStatus',
            label: textResource.coApplicantMilitaryTypeLabel,
            placeholder: textResource.coApplicantMilitaryTypePlaceholder,
            labelOutside: true,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: MILITARY_TYPES,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'politicallyExposedPerson',
            label: textResource.isCoApplicantPepLabel,
            tooltip: tooltip(textResource.isCoApplicantPepTooltip),
            placeholder: textResource.chooseOnePlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.coAppHousingContactDataHeadline,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            coApp: true,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'homeType',
            label: textResource.coApplicantHomeTypeLabel,
            placeholder: textResource.coApplicantHomeTypePlaceholder,
            tooltip: tooltip(textResource.coApplicantHomeTypeTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: HOME_TYPES,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'homeTypeCategory',
            label: textResource.coApplicantHomeTypeCategoryDescriptionLabel,
            placeholder: textResource.coApplicantHomeTypeCategoryDescriptionPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: HOME_TYPE_CATEGORIES,
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          sectionName: 'coApplicant',
          form: 'Select',
          props: {
            name: 'moveInDate',
            options: DATAYEARSELECT,
            label: textResource.moveInDateLabelCoApplicant,
            placeholder: textResource.moveInDatePlaceholderCoApplicant,
            validate: validateRequiredButtonGroup,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            type: 'tel',
            label: textResource.coApplicantHomeCostLabel,
            placeholder: textResource.coApplicantHomeCostPlaceholder,
            tooltip: tooltip(textResource.coApplicantHomeCostTooltip),
            hasValidateFunction: true,
            validate: homeCost,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 4,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'otherLivingCost',
            label: textResource.coApplicantOtherLivingCostsLabel,
            placeholder: textResource.coApplicantOtherLivingCostPlaceholder,
            tooltip: tooltip(textResource.coApplicantOtherLivingCostsTooltip),
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            hasValidateFunction: true,
            validate: otherCost,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.coAppEducationWorkDataHeadline,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            coApp: true,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'educationLevel',
            label: textResource.coApplicantEducationLabel,
            labelOutside: true,
            placeholder: textResource.coApplicantEducationPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: EDUCATION_TYPES,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentType',
            label: textResource.coApplicantEmploymentTypeLabel,
            labelOutside: true,
            placeholder: textResource.coApplicantEmploymentTypePlaceholder,
            tooltip: tooltip(textResource.coApplicantEmploymentTypeTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: EMPLOYMENT_TYPES,
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSince',
            validate: validateEmploymentSinceYear,
            label: textResource.coApplicantEmploymentSinceYearLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.coApplicantEmploymentSinceYearLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'coApplicant',
                    label: textResource.selfEmployedSinceYearDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'coApplicant',
                    label: textResource.studentSinceDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'coApplicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'coApplicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                  {
                    name: 'unemployed',
                    sectionName: 'coApplicant',
                    label: textResource.unemployedSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.coApplicantEmploymentSinceYearPlaceholder,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.coApplicantEmploymentSinceYearTooltip),
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSinceMonth',
            validate: validateEmploymentSinceMonth,
            hasValidateFunction: true,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.coApplicantEmploymentSinceMonthLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'coApplicant',
                    label: textResource.selfEmployedSinceMonthDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'coApplicant',
                    label: textResource.studentSinceMonthDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'coApplicant',
                    label: textResource.retiredSinceMonthDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'coApplicant',
                    label: textResource.disabilityPensionSinceMonthDynamicLabel,
                  },
                ],
              },
            ],
            label: textResource.coApplicantEmploymentSinceMonthLabel,
            placeholder: textResource.coApplicantEmploymentSinceMonthPlaceholder,
            visibleWhen: 'return (formState.values.coApplicant.employmentSince > new Date().getFullYear() - 4);',
            tooltip: tooltip(textResource.coApplicantEmploymentSinceMonthTooltip),
            layout: {
              start: 4,
              end: 5,
            },
          },
        },
        {
          component: 'EmployerName',
          sectionName: 'coApplicant',
          props: {
            name: 'employerName',
            label: textResource.coApplicantEmployerNameLabel,
            placeholder: textResource.coApplicantEmployerNamePlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployerNameTooltip),
            hasValidateFunction: true,
            validate: employerName,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType !== undefined && formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired");',
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'occupationTitle',
            label: textResource.coApplicantOccupationTitleLabel,
            placeholder: textResource.occupationTitlePlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredTextInput,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType !== undefined && (formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired" && formState.values.coApplicant.employmentType !== "student" && formState.values.coApplicant.employmentType !== "unemployed") );',
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'occupationCategory',
            label: textResource.occupationCategoryLabel,
            placeholder: textResource.coApplicantOccupationPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType !== undefined && (formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired" && formState.values.coApplicant.employmentType !== "student" && formState.values.coApplicant.employmentType !== "unemployed") );',
            options: OCCUPATION_CATEGORY,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentTo',
            type: 'tel',
            label: textResource.coApplicantEmployedToYearLabel,
            placeholder: textResource.coApplicantEmployedToYearPlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployedToTooltip),
            hasValidateFunction: true,
            validate: validateEmploymentToYear,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType === "temporary" || formState.values.coApplicant.employmentType === "parentalLeave");',
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentToMonth',
            label: textResource.coApplicantEmployedToMonthLabel,
            labelOutside: true,
            placeholder: textResource.employedToMonthPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType === "temporary" || formState.values.coApplicant.employmentType === "parentalLeave");',
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'monthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.coApplicantMonthlyIncomeBeforeTaxLabel,
            placeholder: textResource.coApplicantMonthlyIncomeBeforeTaxPlaceholder,
            validate: validateMonthlySalaryBeforeTax,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'monthlyIncomeAfterTax',
            isNumber: true,
            type: 'tel',
            label: textResource.coApplicantMonthlyIncomeAfterTaxLabel,
            placeholder: textResource.coApplicantMonthlyIncomeAfterTaxPlaceholder,
            hasValidateFunction: true,
            validate: monthlySalaryAfterTax,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'spouseMonthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.spouseMonthlyIncomeLabel,
            placeholder: textResource.spouseMonthlyIncomePlaceholder,
            hasValidateFunction: true,
            validate: spouseMonthlyIncomeBeforeTaxValidator,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.civilStatus && (formState.values.coApplicant.civilStatus === "married" || formState.values.coApplicant.civilStatus === "partner"));',
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Checkbox',
          sectionName: 'coApplicant',
          props: {
            name: 'otherIncome',
            label: textResource.coApplicantOtherIncomeLabel,
            labelLayout: 'center; margin-right: 0;',
            tooltip: tooltip(textResource.coApplicantOtherIncomeTooltip),
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            buttonLayout: 'toggle',
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'otherMonthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.coApplicantGrossOtherIncomeLabel,
            placeholder: textResource.coApplicantGrossOtherIncomePlaceholder,
            hasValidateFunction: true,
            validate: otherIncomeBeforeTax,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.otherIncome !== undefined && formState.values.coApplicant.otherIncome === true);',
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.coAppDebtBreakdownLabel,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.currentLoansDesc,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            transparent: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ExpandingGridList',
          props: {
            name: 'currentLoanList',
            sectionName: 'coApplicant',
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            addButtonLabel: textResource.loanAdd,
            removeButtonLabel: textResource.loanRemoval,
            columns: [
              {
                component: 'Select',
                name: 'type',
                placeholder: textResource.creditCardPlaceholder,
                hasValidateFunction: true,
                label: textResource.loanTypeLabel,
                validate: validateRequiredButtonGroup,
                options: LOAN_TYPES,
              },
              {
                component: 'TextInput',
                name: 'amount',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanAmountLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.coApplicantLoanAmountTooltip),
              },
              {
                component: 'TextInput',
                name: 'monthlyPayment',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanMonthlyCostLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.coApplicantLoanMonthlyCostTooltip),
              },
              {
                component: 'Checkbox',
                name: 'transfer',
                label: textResource.loanTransferLabel,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['consumer', 'creditCard'],
                  initialValue: false,
                },
              },
              {
                component: 'Select',
                name: 'loanShare',
                label: textResource.houseLoansPartLabel,
                hasValidateFunction: true,
                validate: validateRequiredButtonGroup,
                placeholder: textResource.civilStatusPlaceholder,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['mortgage', 'car'],
                },
                options: [
                  {
                    label: textResource.houseLoansLoanIsOwnedByApplicant,
                    value: '100',
                  },
                  {
                    label: textResource.houseLoansLoanIsSharedWithCoApplicant,
                    value: '50',
                  },
                ],
              },
            ],
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'TestCurrentLoanSummary',
          props: {
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            tooltip: tooltip(textResource.currentLoansSumTooltip),
            title: textResource.currentLoansSumTitle,
            newLoanLabel: textResource.currentLoansSumNew,
            refinanceLoanLabel: textResource.currentLoansSumRefinance,
            totalLabel: textResource.currentLoansSumTotal,
            suffix: textResource.loanCalculatorCurrencySuffix,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'TotalLoanWarning',
          props: {
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            text: textResource.currentLoansTotalWarning,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            label: textResource.applicationLoanAmountLabel,
            value: 'totalAmount',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              name: 'totalAmount',
              type: 'tel',
              tooltip: tooltip(textResource.applicationLoanAmountTooltip),
              maxValue: LOAN_LIMITS.UP_TO,
              suffix: textResource.loanCalculatorCurrencySuffix,
              placeholder: textResource.loanCalculatorTotalLoanAmountPlaceholder,
              validate: onlyNumbers,
              validateOnRegister: true,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            name: 'totalAmount',
            showButtons: true,
            type: 'tel',
            lead: true,
            validate: validateTotalLoan,
            sliderConfig: {
              range: {
                min: LOAN_LIMITS.FROM,
                max: LOAN_LIMITS.UP_TO,
              },
              step: LOAN_LIMITS.STEP,
            },
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'hasCreditCard',
            label: textResource.coApplicantCreditCardLabel,
            labelOutside: true,
            placeholder: textResource.coApplicantCreditCardPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            options: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.acceptDataHeadline,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          props: {
            name: 'purpose',
            label: textResource.purposeHeaderText,
            labelOutside: true,
            placeholder: textResource.purposeHeaderPlaceholder,
            tooltip: tooltip(textResource.purposeTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: LOAN_PURPOSES,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'AutoAccept',
          props: {
            name: 'autoAccept',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'CreditCheckConsent',
          form: 'Checkbox',
          props: {
            name: 'creditCheckConsent',
            text: textResource.acceptCreditCheck,
            linkInText: true,
            links: 'https://www.omalaina.fi/kyselyjarjestelman/',
            hasValidateFunction: true,
            validate: validateRequiredCheckbox,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.acceptNewsletterConsentText,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'AcceptAgreement',
          form: 'Checkbox',
          props: {
            name: 'acceptAgreement',
            text: textResource.acceptAgreement,
            linkText: textResource.termsAndIntegrityPolicyLinksIntegrityPolicy,
            linkTextPolicy: textResource.termsAndIntegrityPolicyLinksTerms,
            linkDataPolicy: {
              FI: 'https://www.omalaina.fi/rekisteriseloste/',
              EN: 'https://www.omalaina.fi/en/privacy-policy/',
            },
            linkUserAgreement: {
              FI: 'https://www.omalaina.fi/rekisteriseloste/',
              EN: 'https://www.omalaina.fi/en/user-agreement/',
            },
            hasValidateFunction: true,
            validate: validateRequiredCheckbox,
            linkInText: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.pCRText,
            transparent: true,
            margin: false,
            center: false,
            PCR: true,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'TotalLoanWarning',
          props: {
            text: textResource.currentLoansTotalWarning,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'SubmitButton',
          sectionName: 'applicant',
          props: {
            label: textResource.formSubmit,
            ctaBtnStyle: true,
            messages: {
              uncaughtSubmitError: textResource.submitErrorUncaught,
              generalSubmitError: textResource.submitErrorGeneral,
            },
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'Disclaimer',
          props: {
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
      ],
    },
  ],
};

export default data;
